/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WebIssueTile from 'components/tiles/WebIssueTile';
import TilePane from 'components/TilePane';
import Measure from 'react-measure';
import BrowserEnvironment from 'instances/browser_environment';
import PopularInIssueTile from 'components/tiles/PopularInIssueTile';
import { getItemId } from 'selectors/item';
import IssueAcquisitionContainer from 'modules/issue/containers/IssueAcquisitionContainer';

const TilesIssue = ({
  issue,
  popularItems,
  tiles,
  disabled,
  loading,
  onNearEnd,
  mobileNavigation,
}) => {
  const [paneHeight, setPaneHeight] = useState(null);

  const renderPopular = () => {
    return (
      <PopularInIssueTile
        key={`popular-in-issue-${issue.id}`}
        providerId={issue.get('provider').id}
        items={popularItems}
        issue={issue}
      />
    );
  };

  const renderArticles = () => {
    return tiles.map(item => {
      const itemId = getItemId(item);
      return <WebIssueTile key={`issue-${itemId}`} itemId={itemId} tileHeight={paneHeight} />;
    });
  };

  const renderTiles = () => {
    if (loading || !paneHeight) {
      return null;
    }

    return [renderPopular(), ...renderArticles()];
  };

  const renderMobileNavigation = () => {
    if (BrowserEnvironment.isMobile()) {
      return mobileNavigation;
    }

    return null;
  };

  return (
    <div>
      <Measure client onResize={({ client }) => setPaneHeight(client.height)}>
        {({ measureRef }) => (
          <TilePane
            innerRef={measureRef}
            showButtons={!!BrowserEnvironment.isDesktop()}
            active={!disabled}
            loading={loading}
            orientation={BrowserEnvironment.isMobile() ? 'vertical' : 'horizontal'}
            onNearEnd={onNearEnd}
          >
            {BrowserEnvironment.isMobile() ? <IssueAcquisitionContainer issue={issue} /> : null}
            {renderMobileNavigation()}
            {renderTiles()}
          </TilePane>
        )}
      </Measure>
      {!BrowserEnvironment.isMobile() ? <IssueAcquisitionContainer issue={issue} /> : null}
    </div>
  );
};

TilesIssue.propTypes = {
  // eslint-disable-next-line react/require-default-props
  issue: PropTypes.object,
  onNearEnd: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  mobileNavigation: PropTypes.any.isRequired,
  tiles: PropTypes.array.isRequired,
  popularItems: PropTypes.array.isRequired,
};

export default TilesIssue;
