import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router/lib/withRouter';
import IssueContainer from './IssueContainer';

class IssueRouterContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      params: { issueId = null, providerId = null, section = null },
    } = this.props;

    this.state = { issueId, providerId, section };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.params.providerId) {
      this.setState({
        providerId: nextProps.params.providerId || null,
        issueId: nextProps.params.issueId || null,
        section: nextProps.params.section || null,
      });
    }
  }

  render() {
    const { issueId, providerId, section } = this.state;
    const { router } = this.props;
    // Make sure the IssueContainer is only rendered when a providerId is set.
    // Through the routes.js it's possible, that these values are not set when going away from this route
    return (
      <IssueContainer
        issueId={issueId}
        providerId={providerId}
        key={`${providerId}-${issueId}`}
        section={section}
        disabled={false}
        router={router}
      />
    );
  }
}

IssueRouterContainer.propTypes = {
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withRouter(IssueRouterContainer);
