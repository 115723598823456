import React, { useEffect } from 'react';
import { shape, func, string } from 'prop-types';
import { STATUS_OK, STATUS_PENDING, STATUS_ERROR } from 'app-constants';
import { translate } from 'instances/i18n';
import withRouter from 'react-router/lib/withRouter';
import BrowserEnv from 'instances/browser_environment';
import TilePane from 'components/TilePane';
import Select from 'components/Select';
import CoverTile from 'components/tiles/CoverTile';
import { sortedIssues } from 'selectors/issues';
import KioskActions from 'actions/KioskActions';
import Auth from 'controllers/auth';
import NewsStandStore from 'stores/NewsStandStore';
import IssuesStore from 'stores/IssuesStore';
import Analytics from 'instances/analytics';
import SegmentAnalytics from 'instances/segmentAnalytics';
import useAlt from 'hooks/useAlt';

function loadCategory(categoryId) {
  if (categoryId === 'my-issues') {
    if (!NewsStandStore.getState().newsStand) {
      KioskActions.fetchNewsStand.defer(Auth.getId());
    }
    KioskActions.fetchAcquiredIssues.defer(Auth.getId());
  } else {
    KioskActions.fetchKiosk.defer(NewsStandStore.getState().newsStand, categoryId, Auth.getId());
  }
}

const KioskContainer = ({ router, params }) => {
  const [newsStandState, issuesState] = useAlt([NewsStandStore, IssuesStore]);
  const category = params.category || 'popular';

  useEffect(() => {
    Analytics.track('View Kiosk', { category });
    SegmentAnalytics.page({
      pageName: 'Kiosk',
      properties: {
        category,
      },
    });

    loadCategory(category);
  }, [category]);

  const isLoading = () => {
    return issuesState.status === STATUS_PENDING;
  };

  const onClickCover = (index) => {
    KioskActions.rememberScrollPosition(index);
  };

  const onScroll = (visibleRange) => {
    KioskActions.rememberScrollPosition(visibleRange[0]);
  };

  const onNearEnd = () => {
    if (!isLoading() && issuesState.categoryId === 'my-issues') {
      const myIssues = issuesState.issues;
      KioskActions.fetchNextAcquiredIssues(myIssues);
    }
  };

  const renderKioskSelect = (newsStand) => {
    if (!newsStand || !newsStand.categories) {
      return null;
    }

    return (
      <Select onChange={(value) => router.push(value)}>
        <option key="popular" value="kiosk">
          {translate('kiosk.navigation.popular')}
        </option>
        <option key="my-issues" value="kiosk/my-issues">
          {translate('kiosk.navigation.my_issues')}
        </option>
        {newsStand.categories.map((newsStandCategory) => (
          <option key={newsStandCategory.id} value={`kiosk/${newsStandCategory.id}`}>
            {newsStandCategory.title}
          </option>
        ))}
      </Select>
    );
  };

  const renderEmptyMyIssues = () => {
    return (
      <div key="empty-my-issues" className="tile-pane-message tile-pane-empty">
        <h1
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: translate('kiosk.no_acquired_issues.title'),
          }}
        />
        <p>{translate('kiosk.no_acquired_issues.message')}</p>
      </div>
    );
  };

  const renderKioskContent = () => {
    let content;

    if (issuesState.issues && issuesState.issues.length > 0) {
      let { issues } = issuesState;

      if (issues.url.indexOf('most_recent.json') === -1 && issues.url.indexOf('issues') === -1) {
        // Do not sort issues in most_recent or in acquired issues
        issues = sortedIssues(issues);
      }
      content = issues
        .filter((issue) => issue.getCoverKey())
        .map((issue) => (
          <CoverTile
            key={issue.id}
            issue={issue}
            onClick={onClickCover}
            numberOfItemsPerRowOnYAxis={2}
          />
        ));
    } else if (!isLoading() && issuesState.status === STATUS_ERROR) {
      content = [
        <p key="message" className="tile-pane-message">
          {translate('kiosk.errors.id_not_found')}
        </p>,
      ];
    } else if (
      !isLoading() &&
      issuesState.status === STATUS_OK &&
      issuesState.categoryId === 'my-issues'
    ) {
      return renderEmptyMyIssues();
    }

    return (
      <TilePane
        className="kiosk-list"
        showButtons={!!BrowserEnv.isDesktop()}
        active={newsStandState.active}
        loading={isLoading()}
        initialIndex={issuesState.scrollPosition}
        orientation={BrowserEnv.isMobile() ? 'vertical' : 'horizontal'}
        onScroll={onScroll}
        onNearEnd={onNearEnd}
        numberOfItemsPerRowOnYAxis={2}
      >
        {content}
      </TilePane>
    );
  };

  return (
    <div>
      {BrowserEnv.isMobile() && renderKioskSelect(newsStandState.newsStand)}
      {renderKioskContent()}
    </div>
  );
};

KioskContainer.propTypes = {
  router: shape({
    push: func.isRequired,
  }).isRequired,
  params: shape({
    category: string,
  }).isRequired,
};

export default withRouter(KioskContainer);
